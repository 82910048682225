<template>
  <div class="video__holder" ref="holder">
    <video 
      ref="video" 
      class="video"
      @play="onPlay"
      @pause="onPause"
      @timeupdate="onTimeUpdate"
      :autoplay="autoplay"
      @loadedmetadata="onMetadataLoaded"
      @waiting="onWaiting"
      @click="(isPlaying || paused) && togglePlay()"
      disableRemotePlayback
      :poster="poster"
    >
      <source :src="source" :type="mime">
      <p>{{ nonSupportedText }}</p>
      <slot></slot>
    </video>
    <div class="video__playbtn" v-show="!isPlaying || isWaiting" @click="togglePlay">
      <Icon name="svg-spinners:ring-resize" v-if="isWaiting"/>
      <Icon name="mdi:play" v-else/>
    </div>
    <div class="video__controls" v-show="(isPlaying || paused) && isHovered">
      <button class="video__controlbtn" @click="togglePlay">
        <Icon name="mdi:play" v-if="!isPlaying"/>
        <Icon name="mdi:pause" v-else/>
      </button>
      <div 
        class="video__progress"
        ref="progressBar"
        @click="scrub"
        @mousemove="isManipulatingTime && scrub($event)"
      >
        <div class="progress__filled" :style="`width: ${progress}%`"></div>
      </div>
      <button class="video__controlbtn" @click="toggle">
        <Icon name="mdi:fullscreen" v-if="!isFullscreen"/>
        <Icon name="mdi:fullscreen-exit" v-else/>
      </button>
      <div class="volume__holder">
        <button class="video__controlbtn" @click="toggleMute">
          <Icon name="mdi:volume-high" v-if="!isMuted"/>
          <Icon name="mdi:volume-off" v-else/>
        </button>
        <div class="volume__control">
          <Slider
            class="volume__slider"
            v-model="volume"
            :min="0"
            :max="100"
            :step="1"
            orientation="vertical"
            direction="rtl"
            showTooltip="drag"
            @change="changeVolume"
          /> 
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Slider from '@vueform/slider';
import '@vueform/slider/themes/default.css';

// TODO: maybe allow multiple?
const props = defineProps({
  source: {
    type: String as PropType<string>,
    required: true
  },
  mime: {
    type: String as PropType<string>,
    default: 'video/mp4'
  },
  poster: {
    type: String as PropType<string>,
    default: null
  },
  nonSupportedText: {
    type: String as PropType<string>,
    default: 'Uw browser ondersteunt geen HTML5 video\'s.'
  },
  autoplay: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  initialVolume: {
    type: Number as PropType<number>,
    default: 100
  }
});

const video = ref<HTMLVideoElement | null>(null);
const holder = ref<HTMLDivElement | null>(null);
const progressBar = ref<HTMLDivElement | null>(null);

const paused = ref<boolean>(false); // Needs to be initialized to false
const timeElapsed = ref<number>(0);
const duration = ref<number>(0);
const isPlaying = ref<boolean>(false);
const volume = ref<number>(props.initialVolume);
const isMuted = ref<boolean>(false);
const isWaiting = ref<boolean>(false);

const progress = computed(() => ((timeElapsed.value / duration.value) * 100) || 0);

const { isFullscreen, toggle } = useFullscreen(video);
const isHovered = useElementHover(holder)
const { pressed: isManipulatingTime } = useMousePressed({ target: progressBar })

function onPlay() {
  isPlaying.value = true;
  isWaiting.value = false;
};

function onPause() {
  isPlaying.value = false;
  paused.value = true;
};

function onWaiting() {
  isWaiting.value = true;
}

function onTimeUpdate() {
  if (duration.value === 0) {
    duration.value = video.value?.duration || 0;
  }

  timeElapsed.value = video.value?.currentTime || 0;
};

function onMetadataLoaded() {
  duration.value = video.value?.duration || 0;
}

function togglePlay() {
  if (video.value?.paused) {
    video.value?.play();
  } else {
    video.value?.pause();
  }
}

function toggleMute() {
  if (video.value) {
    video.value.muted = !video.value.muted;
  }

  isMuted.value = !isMuted.value;
}

function changeVolume(value: number) {
  if (!video.value) return;

  video.value.volume = value / 100;
}

function scrub(e: MouseEvent) {
  if (!video.value || !e.currentTarget) return;

  const target = e.currentTarget as HTMLDivElement;
  const scrubTime = (e.offsetX / target.offsetWidth) * video.value.duration;

  video.value.currentTime = scrubTime;
}
</script>
